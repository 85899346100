import { render, staticRenderFns } from "./privacyAgreementB.vue?vue&type=template&id=277f3337&scoped=true&"
import script from "./privacyAgreementB.vue?vue&type=script&lang=js&"
export * from "./privacyAgreementB.vue?vue&type=script&lang=js&"
import style0 from "./privacyAgreementB.vue?vue&type=style&index=0&id=277f3337&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "277f3337",
  null
  
)

export default component.exports